import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Api_Url } from "../../api_calls/url";

const ComingSoon = () => {
  const targetDate = new Date("2025-02-06T00:00:00");

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [email, setEmail] = useState("");
  const [subing, setSubing] = useState(false);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [targetDate]);

  const handleNotify = async () => {
    if (!email) {
      toast.error("Please enter a valid email address");
      return;
    }

    try {
      setSubing(true);
      const response = await axios.post(`${Api_Url}/api/v1/notify_me`, { email });
      setSubing(false);
      toast.success(response.data.message || "Successfully subscribed!");
      setEmail(""); // Clear the input field after successful submission
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to subscribe. Try again.");
      setSubing(false);
    }
  };

  return (
    <div className="main-wrapper">
      <ToastContainer position="top-center" />
      <div className="bg-img">
        {/* Background Images */}
      </div>
      <div className="content">
        <div className="container">
          <div className="maintenance-sec">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="maintenance-wrap">
                  <h2>We’re getting ready to launch</h2>
                  <p>We'll be here soon with our new awesome site, subscribe to be notified.</p>
                  <div className="email-notify">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-info">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter your Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <button type="button" className="btn btn-primary" onClick={handleNotify}>
                          {subing? "..." :"Notify Me"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <h6>Coming Soon</h6>
                  <div className="countdown-container">
                    {/* Countdown Elements */}
                    <div className="countdown-el days-c">
                      <p>{timeLeft.days}</p>
                      <span>Days</span>
                    </div>
                    <div className="countdown-el hours-c">
                      <p>{timeLeft.hours}</p>
                      <span>Hours</span>
                    </div>
                    <div className="countdown-el mins-c">
                      <p>{timeLeft.minutes}</p>
                      <span>Minutes</span>
                    </div>
                    <div className="countdown-el secs-c">
                      <p>{timeLeft.seconds}</p>
                      <span>Seconds</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-center">
                <img src="/assets/img/coming-soon.png" className="img-fluid" alt="image" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="coming-back">
                <p>We'll Be Back Shortly</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
