import React from "react";

const OverviewCards = ({
  title,
  value,
  subTitle,
  subValue,
  options,
  svgIcon,
  hasButton = false,
  hasDashTotal = true,
}) => {
  return (
    <div class="col-xl-3 col-sm-6 d-flex">
      <div class="dash-card flex-fill">
        <div class="dash-header">
          <div class="dash-widget-header">
            <div class="d-flex align-items-center">
              <span class="dash-widget-icon">
                <img src={`${svgIcon}`} alt="img" />
              </span>
              <div class="dash-widget-info">
                <h6>{title}</h6>
                <h5>{value}</h5>
              </div>
            </div>
            <select>
              <option>2023</option>
              <option>2022</option>
            </select>
          </div>
          {hasDashTotal && (
            <div class="dash-total">
              <p>
                {subTitle}: <span>{subValue}</span>
              </p>
              {/* <div class="badge-success">+2.58</div> */}
            </div>
          )}

          {hasButton && (
            <div class="dash-total">
              <button class="btn btn-primary">Withdraw</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OverviewCards;
