import { toast } from 'react-toastify';
import APIs from '../../Api';

const GetRoom = async () => {
  try {
    const response = await APIs.get(`/get_my_rooms`);

    console.log(response?.data, 'date');

    if (response?.data?.message?.toLowerCase() === 'success') {
      toast('Successfully');

      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const GetNormalRoom = async () => {
  try {
    const response = await APIs.get(`/get_my_normal_rooms`);

    console.log(response?.data, 'date');

    if (response?.data?.message?.toLowerCase() === 'success') {
      toast('Successfully');

      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const uploadImages = async (payload) => {
  try {
    console.log(payload, 'loving');
    const response = await APIs.post(
      `/cloudinary_multiple_file_upload`,
      payload
    );

    if (response?.data?.message === 'success') {
      toast('successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const RoomServices = {
  GetRoom,
  uploadImages,
  GetNormalRoom,
};

export default RoomServices;
