import axios from "axios";
import { toast } from "react-toastify";
import { Api_Url } from "../api_calls/url";

const APIs = axios.create({
  baseURL: `${Api_Url}/api/v1`,
  headers: {
    // Accept: 'application/json',
    // Authorization: `Bearer ${localStorage.getItem('token') ?? ''}`,
  },
});

APIs.interceptors.request.use(function (config) {
  if (navigator.onLine) {
    config.headers.Accept = "application/json";

    config.headers.Authorization = `Bearer ${
      localStorage.getItem("airdrops_token") ?? ""
    }`;

    return config;
  } else {
    toast("No internet connection", {
      toastId: "network error",
    });
  }
});

APIs.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (!err.response) {
      // Handle network error when response is not available
      toast("Network Error: Unable to reach the server.");
      return Promise.reject({
        message: "Network Error: Unable to reach the server.",
      });
    }
    throw err.response.data;
  }
);

export default APIs;
