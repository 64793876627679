import { toast } from "react-toastify";
import APIs from "../../Api";
import axios from "axios";

const getSingleService = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_services/${payload}`);

    if (response?.data?.message === "success") {
      toast("successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getAllCategory = async () => {
  try {
    const response = await APIs.get(`/get_all_category`);

    if (response?.data?.message === "success") {
      toast("successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const vinDecoder = async (vin) => {
  const options = {
    method: "GET",
    // url: "https://vin-decoder7.p.rapidapi.com/vin",
    // url: "https://vindecoder.p.rapidapi.com/decode_vin",
    url: `https://api.vehicledatabases.com/vin-decode/${vin}`,
    // params: {
    //   vin: vin,
    // },
    headers: {
      // "x-rapidapi-key": "56b2abc0f1msh7619918e6dc4d36p1d80cfjsn5431da79ae69",
      // "x-rapidapi-host": "vin-decoder7.p.rapidapi.com",
      "x-AuthKey": "95bf20e97b80459fa2ba77092e1f0569",
    },
  };

  try {
    const response = await axios.request(options);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const uploadImage = async (payload) => {
  try {
    const response = await APIs.post(`/upload_through_cloudinary`, payload);

    if (response?.data?.message === "success") {
      toast("successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getServices = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_active_services/${payload?.page}/${payload?.limit}`
    );
    console.log(response, "response from servics service");
    if (response?.data?.message === "success") {
      toast("successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const filterServices = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_active_services_by_category/${payload?.category}/${payload?.sub_category}/${payload?.page}/${payload?.limit}`
    );
    console.log(response, "response from servics service");
    if (response?.data?.message === "success") {
      toast("successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getUserBookings = async (paginate) => {
  try {
    const response = await APIs.get(`/get_my_bookings_services/${paginate}`);

    console.log(response, "response user booking");

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const bookService = async (userData) => {
  try {
    const response = await APIs.post(`/booking_services`, userData);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (err) {
    throw err;
  }
};

const serviceService = {
  getServices,
  bookService,
  getSingleService,
  getUserBookings,
  uploadImage,
  filterServices,
  getAllCategory,
  vinDecoder,
};

export default serviceService;
