import { configureStore } from "@reduxjs/toolkit";
import { serviceSlice } from "../manage_states/gen-states/services/slice";
import roomSlice from "../manage_states/gen-states/room/roomSlice";
import { biddingSlice } from "../manage_states/dashboard/provider/bidding/bidding-slice";
import authSlice from "../manage_states/gen-states/auth/authSlice";
import { categorySlice } from "../manage_states/category/category_slices";
import paymentSlice from "../manage_states/gen-states/payment/paymentSlice";
// import { serviceSlice } from "../manage_states/provider/services/slice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    services: serviceSlice.reducer,
    bidding: biddingSlice.reducer,
    room: roomSlice.reducer,
    category: categorySlice.reducer,
    payment: paymentSlice,
  },
});
