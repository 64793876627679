import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import RoomServices from './roomsService';

export const GetRooms = createAsyncThunk(
  'room/get_room',
  async (credentials, thunkAPI) => {
    try {
      const delivered = await RoomServices.GetRoom();
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const GetNormalRooms = createAsyncThunk(
  'room/get_normal_room',
  async (credentials, thunkAPI) => {
    try {
      const delivered = await RoomServices.GetNormalRoom();
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const UploadImages = createAsyncThunk(
  'room/UploadImages',
  async (requestData, thunkAPI) => {
    try {
      const delivered = await RoomServices.uploadImages(requestData);

      return delivered;
    } catch (e) {
      toast(e.message);
      throw thunkAPI.rejectWithValue(e);
    }
  }
);
