import { createAsyncThunk } from "@reduxjs/toolkit";
import biddingService from "./bidding-services";

export const getBiddings = createAsyncThunk(
  "bidding/getBiddings",
  async (page, thunkAPI) => {
    try {
      const delivered = await biddingService.getBiddings(page);
      console.log(delivered, "delivered request");
      return delivered;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getUserBiddings = createAsyncThunk(
  "bidding/getUserBiddings",
  async (page, thunkAPI) => {
    try {
      const delivered = await biddingService.getUserBiddings(page);
      console.log(delivered, "delivered request");
      return delivered;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getSingleBidding = createAsyncThunk(
  "bidding/getSingleBidding",
  async (id, thunkAPI) => {
    try {
      console.log(id, "getSingle id");
      const delivered = await biddingService.getSingleBidding(id);
      console.log(delivered, "vendor single bid received");
      return delivered;
    } catch (e) {
      console.log(e, "single bid received error");
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const placeBid = createAsyncThunk(
  "bidding/placeBid",
  async (requestData, thunkAPI) => {
    try {
      const { id, data } = requestData;
      console.log(data, "request data from edit");
      const delivered = await biddingService.placeBid(id, data);
      window.location.href = "/provider/booking";
      console.log(delivered, "delivered request");
      return delivered;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
