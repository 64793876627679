import APIs from "../../../Api";

const getBiddings = async (page) => {
  try {
    const response = await APIs.get(`/get_all_bid_bookings_services/${page}`);

    console.log(response, "response from biddings service");

    if (response?.status === 201) {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getUserBiddings = async (page) => {
  try {
    const response = await APIs.get(`/get_all_my_biddings_services/${page}`);
    // '/api/v1/get_all_my_biddings_services/:page/:limit'
    console.log(response, "response from");

    if (response?.status === 201) {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleBidding = async (id) => {
  try {
    const response = await APIs.get(`/get_single_bid_booked_services/${id}`);

    console.log(response, "response from single bidding service");

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const placeBid = async (id, data) => {
  try {
    const response = await APIs.patch(`/edit_bid_booking_service/${id}`, data);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (err) {
    throw err;
  }
};

const biddingService = {
  getBiddings,
  getSingleBidding,
  placeBid,
  getUserBiddings,
};

export default biddingService;
