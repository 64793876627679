// src/features/auth/authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { GetNormalRooms, GetRooms, UploadImages } from './roomsServiceSync';

const initialState = {
  roomLoading: false,
  roomImageLoader: false,
  rooms: [],
  normalRooms: [],
  roomError: null,
  roomMessage: '',
  current_room: '',
  messages: null,
  selectedRoom: {},
  roomImageDetails: [],
};

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    reset: (state) => {
      state.roomLoading = false;
      state.roomImageLoader = false;
      state.rooms = [];
      state.normalRooms = [];
      state.roomError = [];
      state.roomMessage = '';
      state.current_room = '';
      state.messages = null;
      state.selectedRoom = {};
      state.roomImageDetails = [];
    },

    updateRoom: (state, { payload }) => {
      state.rooms = payload;
    },

    updateNormalRoom: (state, { payload }) => {
      state.normalRooms = payload;
    },

    updateCurrentRoom: (state, { payload }) => {
      state.current_room = payload;
    },

    updateCurrentNormalRoom: (state, { payload }) => {
      state.current_room = payload;
    },

    updateMessage: (state, { payload }) => {
      state.messages = payload;
    },

    updateSelectedRoom: (state, { payload }) => {
      state.selectedRoom = payload;
    },

    updateSelectedNormalRoom: (state, { payload }) => {
      state.selectedRoom = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetRooms.pending, (state) => {
        state.roomLoading = true;
      })
      .addCase(GetRooms.fulfilled, (state, { payload }) => {
        state.roomLoading = false;
        state.rooms = payload.data;
        state.roomMessage = payload.message;
      })
      .addCase(GetRooms.rejected, (state, { payload }) => {
        state.roomLoading = false;
        state.roomMessage = payload.message;
      });

    builder
      .addCase(GetNormalRooms.pending, (state) => {
        state.roomLoading = true;
      })
      .addCase(GetNormalRooms.fulfilled, (state, { payload }) => {
        state.roomLoading = false;
        state.normalRooms = payload.data;
        state.roomMessage = payload.message;
      })
      .addCase(GetNormalRooms.rejected, (state, { payload }) => {
        state.roomLoading = false;
        state.roomMessage = payload.message;
      });

    builder
      .addCase(UploadImages.pending, (state) => {
        state.roomImageLoader = true;
      })
      .addCase(UploadImages.fulfilled, (state, { payload }) => {
        state.roomImageLoader = false;
        state.roomMessage = payload.message;
        state.roomImageDetails = payload.data;
      })
      .addCase(UploadImages.rejected, (state, { payload }) => {
        state.roomImageLoader = false;
        state.roomMessage = payload?.message;
      });
  },
});

export const {
  reset,
  updateRoom,
  updateNormalRoom,
  updateCurrentRoom,
  updateCurrentNormalRoom,
  updateMessage,
  updateSelectedRoom,
  updateSelectedNormalRoom,
} = roomSlice.actions;
export const roomSelector = (state) => state.room;
export default roomSlice;
