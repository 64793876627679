import "./App.css";
import { Route, Routes } from "react-router-dom";
import NavFooterWrapper from "./pages/nav_footer_wrapper";
import Hompage from "./pages/home";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ServicesPage from "./pages/services";
import LoginPage from "./pages/login";
import SignupPage from "./pages/signup";
import UserSignupPage from "./pages/user-signup";
import ProviderSignupPage from "./pages/provider-signup";
import ProviderDashboardWrapper from "./pages/dashboard/prodvider_dashboard_wrapper";
import SwipeableTemporaryDrawer from "./components/dashboard/sidebars/drawer";
import ProviderIndex from "./pages/dashboard/provider";
import ProviderVerification from "./components/dashboard/provider/profile-verification";
import CustomerDashboardWrapper from "./pages/dashboard/customer_dashboard_wrapper";
import CustomerIndexPage from "./pages/dashboard/customer";
import CustomerBookListPage from "./pages/dashboard/customer/booking";
import PhoneOtpPage from "./pages/phone-otp";
import EmailOtpPage from "./pages/email-otp";
import ServiceList from "./pages/dashboard/provider/ServiceList";
import CreateService from "./pages/dashboard/provider/CreateService";
import ProviderPrivateRoute from "./RouteHelpers/ProviderPrivateRoute";
import CustomerVerification from "./components/dashboard/customers/profileVerification";
import CustomerChangePassword from "./components/dashboard/customers/changePassword";
import CustomerProfile from "./components/dashboard/customers/customerProfile";
import BookService from "./components/dashboard/customers/book-service";
import BookPayment from "./components/dashboard/customers/book-payment";
import BookingDone from "./components/dashboard/customers/booking-done";
import FavoriteListPage from "./pages/dashboard/customer/favorites";
import { useContext, useEffect } from "react";
import { AppContext } from "./context/context";
import ServiceDetailsPage from "./components/services/serviceDetailsPage";
import CustomerChat from "./components/dashboard/customers/CustomerChat";
import ProfileSetting from "./pages/dashboard/provider/ProfileSetting";
import CustomerServiceChat from "./components/dashboard/customers/CustomerServiceChat";
import CustomerServicesList from "./components/dashboard/customers/services-list";
import VehicleCheckListComp from "./components/dashboard/customers/vehicle-check-listComp";
import ChatScreen from "./components/dashboard/provider/ChatScreen";
import CustomerPrivateRoute from "./RouteHelpers/CustomerPrivateRoute";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Earnings from "./components/dashboard/provider/Earnings";
import VehicleInfo from "./components/dashboard/customers/VehicleInfo";
import Biddings from "./components/dashboard/provider/Biddings";
import VehicleInspection from "./components/dashboard/customers/Vehicle-Inspection";
import CustomerWallet from "./components/dashboard/customers/CustomerWallet";
import Payout from "./components/dashboard/provider/Payout";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ComingSoon from "./components/coming-soon/ComingSoon";

// assets/img/avatar-profile-photo.png

function App() {
  const { socket } = useContext(AppContext);

  useEffect(() => {
    console.log("native work");
    socket.emit("connected");
  }, []);

  socket.off("connected-user").on("connected-user", (room) => {
    console.log(room, "visions");
  });
  return (
    <>
     <ComingSoon />
    </>
  );
}

export default App;
