import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getBiddings,
  getSingleBidding,
  placeBid,
  getUserBiddings,
} from "./bidding-services-sync";

export const biddingSlice = createSlice({
  name: "bidding",
  initialState: {
    // _id: "650ed4eb0e6ee16399fa68ee",
    requests: {},
    singleRequest: {},
    userBids: {},
    singleBidding: {},
    isFetching: false,
    isPending: false,
    isSuccess: false,
    isError: false,
    isUpdateError: false,
    isUpdateSuccess: false,
    errorMessage: "",
    updateErrorMessage: "",
    placeBidMessage: "",
    placingBid: false,
    placeBidSuccess: false,
    placeBidError: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBiddings.pending, (state) => {
        state.isFetching = true;
        console.log("got here and fecthing");
      })
      .addCase(getBiddings.fulfilled, (state, { payload }) => {
        console.log("got here");
        state.isFetching = false;
        state.isSuccess = true;
        state.requests = payload;
        console.log(state.requests, "requests");
      })
      .addCase(getBiddings.rejected, (state) => {
        console.log("getBiddings");
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getUserBiddings.pending, (state) => {
        state.isFetching = true;
        console.log("got here and fecthing");
      })
      .addCase(getUserBiddings.fulfilled, (state, { payload }) => {
        console.log("got here");
        state.isFetching = false;
        state.isSuccess = true;
        state.userBids = payload;
        console.log(state.userBids, "user bids");
      })
      .addCase(getUserBiddings.rejected, (state) => {
        console.log("get user Biddings");
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getSingleBidding.pending, (state) => {
        state.isFetching = true;
        console.log("got here and fecthing");
      })
      .addCase(getSingleBidding.fulfilled, (state, { payload }) => {
        console.log("got here");
        state.isFetching = false;
        state.isSuccess = true;
        state.singleBidding = payload;
        console.log(state.singleBidding, "single bidding");
      })
      .addCase(getSingleBidding.rejected, (state) => {
        console.log("getBiddings");
        state.isFetching = false;
        state.isError = true;
      })

      .addCase(placeBid.pending, (state, action) => {
        state.placingBid = true;
        console.log("pending");
      })
      .addCase(placeBid.fulfilled, (state, action) => {
        state.placingBid = false;
        state.placeBidSuccess = true;
        // state.singleRequest = action.payload;
        state.placeBidMessage = action.payload;

        console.log(state.placeBidMessage, "slice request updated");
      })
      .addCase(placeBid.rejected, (state, action) => {
        state.placingBid = false;
        state.placeBidError = true;
        state.placeBidMessage = action.payload;
        console.log("is rejected", state.placeBidMessage);
      });
  },
});

export const { clearState } = biddingSlice.actions;
export const biddingSelector = (state) => state.bidding;
