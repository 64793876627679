import React, { useRef, useState } from 'react'
import Webcam from 'react-webcam'
// import { BsCamera } from "react-icons/bs";
import faceIO from '@faceio/fiojs'

const faceio = new faceIO('fioae4bd')
const FaceVerification = () => {
  const webcamRef = useRef(null)
  const [cameraActive, setCameraActive] = useState(false)
  const [capturedImage, setCapturedImage] = useState(null)

  const startCamera = () => {
    setCameraActive(true)
  }

  const capture = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()
      // Do something with the captured image
      setCapturedImage(imageSrc)
      setCameraActive(false)
      console.log(imageSrc)
    }
  }

  const check = () => {
    console.log('nnkskdsk, checking')
  }
  const enrollNewUser = async () => {
    // call to faceio.enroll() here will automatically trigger the on-boarding process
    const userInfo = await faceio.enroll({
      locale: 'auto', // Default user locale
      payload: {
        /* The payload we want to associate with this particular user which is forwarded back to us upon future authentication of this user.*/
        whoami: '65aba8b9c21ef7a3ff8c688e', // Dummy ID linked to this particular user
        email: 'ugorjiezechimere@gmail.com',
      },
    })
    console.log(userInfo)
  }

  async function authenticateUser() {
    try {
      const userData = await faceio.authenticate({
        locale: 'auto', // Default user locale
      })

      console.log('Success, user identified')
      // Grab the facial ID linked to this particular user which will be same
      // for each of his successful future authentication. FACEIO recommend
      // that your rely on this Facial ID if you plan to uniquely identify
      // all enrolled users on your backend for example.
      console.log('Linked facial Id: ' + userData.facialId)
      // Grab the arbitrary data you have already linked (if any) to this particular user
      // during his enrollment via the payload parameter of the enroll() method.
      console.log('Payload: ' + JSON.stringify(userData.payload)) // {"whoami": 123456, "email": "john.doe@example.com"} from the enroll() example above
    } catch (err) {
      console.log('error', err)
    }
  }
  return (
    <>
      <div className='linked-item'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='linked-acc'>
              <span className='link-icon'>
                <img src='/assets/img/icons/document-pdf.svg' alt='Icons' />
              </span>
              <div className='linked-info'>
                <h6>
                  <a href='javascript:void(0);'>Face Verification</a>
                </h6>
                <p>Not Yet Verified </p>
              </div>
            </div>
          </div>
          <div className='col-md-4 text-md-end'>
            <div className='verfification-modal-block'>
              <a
                href='javascript:void(0);'
                className='link-close'
                data-bs-toggle='tooltip'
                data-bs-placement='left'
                data-bs-title='Not Verified'
              >
                <i className='fa-solid fa-circle-xmark'></i>
              </a>
              <button
                className='btn btn-primary btn-connect'
                onClick={authenticateUser}
              >
                Change
              </button>
              <a href='javascript:void(0);' className='btn-acc'>
                Remove
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class='modal fade custom-modal verify-modal' id='change-image'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content doctor-profile'>
            <div className='modal-header verfication-modal-head'>
              <h5 className='modal-title'>Verify Your Identity</h5>
              <p>Capture your face</p>
            </div>
            <div className='modal-body'>
              <div>
                <div className='wallet-add'>
                  {/* <div className="form-group">
                    <label>Document Name</label>
                    <input class="form-control" name="phone" type="text" />
                  </div> */}
                  <div class='file-uploaded-mail' style={{ height: 'inherit' }}>
                    {cameraActive && (
                      <>
                        <Webcam
                          height={300}
                          width={400}
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat='image/jpeg'
                        />
                        <button
                          onClick={capture}
                          className='btn btn-primary w-20 m-auto mt-2'
                        >
                          Capture
                        </button>
                      </>
                    )}
                    {!cameraActive && capturedImage && (
                      <>
                        <img src={capturedImage} alt='Captured' />
                        <div className='document-upload-file'>
                          <button
                            onClick={startCamera}
                            className='btn btn-primary w-20 m-2'
                          >
                            Retake
                          </button>
                          <button
                            className='btn btn-success w-20 m-2'
                            data-bs-toggle='modal'
                            data-bs-target='#success-document'
                          >
                            upload
                          </button>
                        </div>
                      </>
                    )}
                    {!cameraActive && !capturedImage && (
                      <img
                        src='/assets/img/icons/camera.gif'
                        className='img-fluid'
                        alt='Upload'
                      />
                    )}
                  </div>
                  {!cameraActive && !capturedImage && (
                    <div className='document-upload-file'>
                      <button
                        className='btn btn-primary w-20 m-auto'
                        onClick={enrollNewUser}
                      >
                        Take Picture
                      </button>
                    </div>
                  )}
                  {/* <div className="modal-submit">
                    <a
                      href="#"
                      className="btn btn-primary w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#success-document"
                    >
                      Continue
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Success mail --> */}
      <div class='modal fade success-modal verify-modal' id='success-document'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body success-body text-center'>
              <span>
                <i className='fa-regular fa-circle-check'></i>
              </span>
              <h3>Success</h3>
              <p className='success-modal-body'>
                Document is sent htmlFor approval for the admin once approved
                you will notified.
              </p>
            </div>
            <div className='popup-btn text-center'>
              <a href='/provider' className='btn btn-primary verify-document'>
                Go to Dashboard
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Success  --> */}
    </>
  )
}

export default FaceVerification
