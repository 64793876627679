/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/context';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetRooms,
  UploadImages,
} from '../../../manage_states/gen-states/room/roomsServiceSync';
import {
  updateCurrentRoom,
  updateMessage,
  updateRoom,
  updateSelectedRoom,
} from '../../../manage_states/gen-states/room/roomSlice';
import moment from 'moment/moment';
import { InfinitySpin } from 'react-loader-spinner';
import Image from 'rc-image';
import { PhotoView } from 'react-photo-view';
import { UserMakePayment } from "../../../manage_states/gen-states/payment/paymentServiceSync";
import axios from 'axios';
import { saveAs } from 'file-saver';

const CustomerServiceChat = () => {
  const dispatch = useDispatch();

  const paymentValue = {
    amount: 200,
    currency: "NGN",
    services: "service title he is paying for",
    serviceId: "service id",
    services_pic: "service pics",
    booked_serviceId: "booked service id",
  };

  const [typedMessage, setTypedMessage] = useState("");

  const [uploadImages, setUploadImages] = useState({ length: 0 });

  const [searchRoom, setSearchRoom] = useState([]);
  const [search, setSearch] = useState("");

  const { user } = useSelector((state) => state.auth);

  const { socket } = useContext(AppContext);

  // const { rooms, current_room, messages, selectedRoom } = useSelector(
  //   (state) => state.room
  // );
  console.log(selectedRoom, "selected room is here now");
  const {
    makingPayment,
    makePaymentSuccessful,
    makePaymentError,
    makePaymentMessage,
  } = useSelector((state) => state.payment);

  console.log(user, "finihing");
  const {
    rooms,
    current_room,
    messages,
    selectedRoom,
    roomImageLoader,
    roomImageDetails,
  } = useSelector((state) => state.room);

  useEffect(() => {
    dispatch(GetRooms());

    socket.emit("customer-new-user", { id: user?._id });
  }, [user]);

  socket.off("new-user").on("new-user", (payload) => {
    dispatch(updateRoom(payload));
  });

  socket.off("room-messages").on("room-messages", (roomMessages) => {
    dispatch(updateMessage(roomMessages));
  });

  const JoinRoom = (room, currentRoom, isPublic = true) => {
    if (!user) {
      return alert("please login");
    }

    socket.emit('join-room', room.room, currentRoom, room);

    dispatch(updateCurrentRoom(room.room));

    dispatch(updateSelectedRoom(room));
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setTypedMessage(value);
  };

  const getFormattedDate = () => {
    const date = new Date();

    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();

    month = month.length > 1 ? month : "0" + month;

    let day = date.getDate().toString();

    day = day.length > 1 ? day : "0" + day;

    return month + "/" + day + "/" + year;
  };

  const todayDate = getFormattedDate();

  console.log(Array.isArray(uploadImages), 'level up');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!typedMessage) return;

    let today = new Date();

    const minutes =
      today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();

    const time = today.getHours() + ":" + minutes;

    const roomId = current_room;
    if (uploadImages.length === 0) {
      socket.emit(
        'message-room',
        roomId,
        typedMessage,
        user,
        time,
        todayDate,
        false,
        []
      );
    } else {
      const ImageDetails = roomImageDetails?.map((item) => {
        return {
          pic_url: item?.image?.secure_url,
          pic_id: item?.image?.public_id,
        };
      });

      socket.emit(
        'message-room',
        roomId,
        typedMessage,
        user,
        time,
        todayDate,
        true,
        ImageDetails
      );

      setUploadImages({ length: 0 });
    }

    setTypedMessage("");
  };

  const handleSearchUser = (e) => {
    const { value } = e.target;
    setSearch(value);

    if (value) {
      const searchForRoom = rooms?.filter((item) =>
        item?.name?.toLowerCase().includes(value?.toLowerCase())
      );

      setSearchRoom(searchForRoom);
    } else {
      setSearchRoom([]);
    }
  };

  const handleMakePayment = (e) => {
    e.preventDefault();
    console.log("clicked payment");
    dispatch(UserMakePayment(paymentValue));
  };

  const handleUploadImage = async (e) => {
    const image = e.target.files;

    if (image) {
      const formData = new FormData();

      Array.from(image).forEach((file, i) => {
        formData.append(`file`, file);
      });

      await dispatch(UploadImages(formData));

      setUploadImages(image);
    }
  };

  useEffect(() => {
    if (roomImageDetails?.length > 0) {
      roomImageDetails.forEach((file, i) => {
        setTypedMessage((value) => value + ' ' + file?.image?.secure_url);
      });
    }
  }, [roomImageDetails]);

  const downloadImage = async (item) => {
    console.log(
      item?.split('/')[item?.split('/').length - 2],
      item?.split('/')[item?.split('/').length - 1].split('.')[0],
      'my love'
    );

    const response = await axios({
      url: item,
      method: 'GET',
      responseType: 'blob', // Important: Use 'blob' for binary data
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([response.data]));
    link.setAttribute(
      'download',
      `${item?.split('/')[item?.split('/').length - 2]}_${
        item?.split('/')[item?.split('/').length - 1].split('.')[0]
      }.${item?.split('/')[item?.split('/').length - 1].split('.')[1]}`
    ); // Custom filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const downloadImage = (imageUrl) => {
  //   saveAs(imageUrl, 'downloaded_image.png'); // 'downloaded_image.png' is the filename
  // };

  return (
    <>
      <div className="content">
        <div className="container">
          <div className="customer-chat">
            <div className="row chat-window">
              <div className="login-back mb-3">
                <a href="/customer">
                  <i className="feather-arrow-left"></i> Back
                </a>
              </div>
              {/* Chat User List */}
              <div className="col-lg-3 chat-cont-left d-flex">
                <div className="card mb-sm-3 mb-md-0 contacts_card flex-fill">
                  <div className="chat-header">
                    <div>
                      <h6>Chats</h6>
                      <p>Start New Conversation</p>
                    </div>

                    {/* <a href='#' className='chat-compose'>
                      <i className='feather-plus'></i>
                    </a> */}
                  </div>
                  <div className="chat-search chat-search-head">
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control search-chat chat-search"
                        onChange={handleSearchUser}
                      />
                      <div className="search-icon-right">
                        <span className="search_btn">
                          <i className="feather-search" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-body contacts_body chat-users-list chat-scroll">
                    <div className="chat-header inner-chat-header pt-0">
                      <div>
                        <h6>Recent</h6>
                      </div>
                    </div>
                    {search !== "" && search !== null && search !== undefined
                      ? searchRoom?.map((item, key) => (
                          <a
                            key={key}
                            onClick={() => JoinRoom(item)}
                            href="javascript:void(0);"
                            className="media d-flex active"
                          >
                            <div className="media-img-wrap flex-shrink-0">
                              <div
                                className={`avatar ${
                                  item?.status === "online"
                                    ? "avatar-online"
                                    : ""
                                }`}
                                style={{ overflow: "hidden" }}
                              >
                                <img
                                  src={
                                    item?.picture
                                      ? item?.picture
                                      : `assets/img/avatar-profile-photo.png`
                                  }
                                  alt="User Imag"
                                  className="avatar-img rounded-circle"
                                />
                              </div>
                            </div>
                            <div className="media-body flex-grow-1">
                              <div>
                                <div className="user-name">
                                  {item?.service_title}
                                </div>
                                <div className="user-last-chat">
                                  {item?.lastMessage}
                                </div>
                              </div>
                              <div>
                                <div className="last-chat-time">
                                  {moment(item?.lastTime).format("hh:mm:ss a")}
                                </div>

                                {item?.client_notification > 0 && (
                                  <div className="badge badge-primary badge-pill">
                                    {item?.client_notification}
                                  </div>
                                )}
                              </div>
                            </div>
                          </a>
                        ))
                      : rooms?.length > 0 &&
                        rooms?.map((item, key) => (
                          <a
                            key={key}
                            onClick={() => JoinRoom(item)}
                            href="javascript:void(0);"
                            className="media d-flex active"
                          >
                            <div className="media-img-wrap flex-shrink-0">
                              <div
                                className={`avatar ${
                                  item?.status === "online"
                                    ? "avatar-online"
                                    : ""
                                }`}
                                style={{ overflow: "hidden" }}
                              >
                                <img
                                  src={
                                    item?.picture
                                      ? item?.picture
                                      : `assets/img/avatar-profile-photo.png`
                                  }
                                  alt="User Imag"
                                  className="avatar-img rounded-circle"
                                />
                              </div>
                            </div>
                            <div className="media-body flex-grow-1">
                              <div>
                                <div className="user-name">
                                  {item?.service_title}
                                </div>
                                <div className="user-last-chat">
                                  {item?.lastMessage}
                                </div>
                              </div>
                              <div>
                                <div className="last-chat-time">
                                  {moment(item?.lastTime).format("hh:mm:ss a")}
                                </div>
                                {item?.client_notification > 0 && (
                                  <div className="badge badge-primary badge-pill">
                                    {item?.client_notification}
                                  </div>
                                )}
                              </div>
                            </div>
                          </a>
                        ))}
                  </div>
                </div>
              </div>
              {/* Chat User List */}
              {/* Chat Content */}
              <div className="col-lg-9 chat-cont-right chat-window-long">
                {/* Chat History */}
                <div className="card chat-window mb-0">
                  {current_room && (
                    <>
                      <div className="card-header msg_head">
                        <div className="d-flex bd-highlight">
                          <a
                            id="back_user_list"
                            href="#"
                            className="back-user-list"
                          >
                            <i className="fas fa-chevron-left" />
                          </a>
                          <div className="img_cont">
                            <img
                              className="rounded-circle user_img"
                              src={
                                selectedRoom?.picture
                                  ? selectedRoom?.picture
                                  : `assets/img/avatar-profile-photo.png`
                              }
                              alt="User"
                            />
                          </div>
                          <div className="user_info">
                            <span>{selectedRoom?.service_title}</span>
                            <p className="mb-0 active">
                              {selectedRoom?.status === "online"
                                ? "Online"
                                : "Offline"}
                            </p>
                          </div>
                        </div>
                        <button
                          // href="/book-payment/9090"
                          onClick={handleMakePayment}
                          className="btn  btn-secondary"
                        >
                          make a payment
                        </button>
                        <div className="chat-options">
                          <ul>
                            {/* <li>
                              <a
                                href="/book-payment/9090"
                                className="btn btn-secondary"
                              >
                                make a payment
                              </a>
                            </li> */}

                            <li>
                              <a href="#">
                                <i className="feather-search" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="feather-video" />
                              </a>
                            </li>
                            <li>
                              <a href="#" className="chat-profile-open">
                                <i className="feather-user" id="task_chat" />
                              </a>
                            </li>
                            <li>
                              <a href="#" className="with-bg">
                                <i className="feather-more-horizontal" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-body msg_card_body chat-scroll pt-0">
                        <ul className="list-unstyled">
                          {messages?.map((item, key) => (
                            <Fragment key={key}>
                              <li className="chat-date mt-0">
                                <span>{item?._id}</span>
                              </li>

                              {item?.messagesByDate?.map((data, key) => (
                                <>
                                  {data?.from?._id === user?._id ? (
                                    <>
                                      {data?.image === true ? (
                                        <>
                                          <li className="media received d-flex">
                                            <div className="avatar flex-shrink-0">
                                              <img
                                                src={
                                                  item?.from?.profile_pic
                                                    ? item?.from?.profile_pic
                                                    : `/assets/img/avatar-profile-photo.png`
                                                }
                                                alt="User Imag"
                                                className="avatar-img rounded-circle"
                                              />
                                            </div>
                                            <div className="media-body flex-grow-1">
                                              <div className="msg-box">
                                                <div className="msg-img">
                                                  <div className="chat-msg-attachments">
                                                    {data?.imageDetails?.map(
                                                      (image, ind) => (
                                                        <div
                                                          key={ind}
                                                          className="chat-attachment"
                                                        >
                                                          <PhotoView
                                                            src={
                                                              image?.pic_url ||
                                                              '/assets/img/chat-img-01.jpg'
                                                            }
                                                          >
                                                            <img
                                                              src={
                                                                image?.pic_url ||
                                                                '/assets/img/chat-img-01.jpg'
                                                              }
                                                              alt="Attachment"
                                                              style={{
                                                                maxHeight:
                                                                  '90px',
                                                              }}
                                                            />
                                                          </PhotoView>
                                                          <a
                                                            href="javascript:void(0);"
                                                            className="chat-attach-download"
                                                            onClick={() =>
                                                              downloadImage(
                                                                image?.pic_url ||
                                                                  '/assets/img/chat-img-01.jpg'
                                                              )
                                                            }
                                                          >
                                                            <i className="fas fa-download" />
                                                          </a>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                                <span className="drop-item message-more-option">
                                                  <a
                                                    href="#"
                                                    className="more-vertical-bar"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    <i className="feather-more-vertical" />
                                                  </a>
                                                  <small className="dropdown-menu">
                                                    <a
                                                      className="dropdown-item"
                                                      href="#"
                                                    >
                                                      <i className="feather-copy me-2" />
                                                      Copy
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="#"
                                                    >
                                                      <i className="feather-trash-2 me-2" />
                                                      Delete
                                                    </a>
                                                  </small>
                                                </span>
                                                <ul className="chat-msg-info name-date">
                                                  <li>Hendrita</li>
                                                  <li>
                                                    <span className="chat-time">
                                                      09:30 am
                                                      <i className="fa-solid fa-check-double un-read" />
                                                    </span>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      ) : (
                                        <li
                                          className="media received d-flex"
                                          key={key}
                                        >
                                          <div className="avatar flex-shrink-0">
                                            <img
                                              src={
                                                item?.from?.profile_pic
                                                  ? item?.from?.profile_pic
                                                  : `/assets/img/avatar-profile-photo.png`
                                              }
                                              alt="User Imag"
                                              className="avatar-img rounded-circle"
                                            />
                                          </div>
                                          <div className="media-body flex-grow-1">
                                            <div className="msg-box">
                                              <div>
                                                <p>{data?.content}</p>
                                              </div>
                                              <span className="drop-item message-more-option">
                                                <a
                                                  href="#"
                                                  className="more-vertical-bar"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i className="feather-more-vertical" />
                                                </a>
                                                <small className="dropdown-menu">
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <i className="feather-copy me-2" />
                                                    Copy
                                                  </a>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <i className="feather-trash-2 me-2" />
                                                    Delete
                                                  </a>
                                                </small>
                                              </span>
                                              <ul
                                                className="chat-msg-info name-date"
                                                style={{
                                                  minWidth: "160px",
                                                }}
                                              >
                                                <li>{data?.from?.name}</li>
                                                <li>
                                                  <span className="chat-time">
                                                    {data?.time}
                                                    <i className="fa-solid fa-check-double read" />
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {data?.image === true ? (
                                        <li className="media sent d-flex">
                                          <div className="avatar flex-shrink-0">
                                            <img
                                              src={
                                                item?.from?.profile_pic
                                                  ? item?.from?.profile_pic
                                                  : `/assets/img/avatar-profile-photo.png`
                                              }
                                              alt="User Imag"
                                              className="avatar-img rounded-circle"
                                            />
                                          </div>
                                          <div className="media-body flex-grow-1">
                                            <div className="msg-box">
                                              <div className="msg-img">
                                                <div className="chat-msg-attachments">
                                                  {data?.imageDetails?.map(
                                                    (image, ind) => (
                                                      <div
                                                        key={ind}
                                                        className="chat-attachment"
                                                      >
                                                        <PhotoView
                                                          src={
                                                            image?.pic_url ||
                                                            '/assets/img/chat-img-01.jpg'
                                                          }
                                                        >
                                                          <img
                                                            src={
                                                              image?.pic_url ||
                                                              '/assets/img/chat-img-01.jpg'
                                                            }
                                                            alt="Attachment"
                                                            style={{
                                                              maxHeight: '90px',
                                                            }}
                                                          />
                                                        </PhotoView>
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="chat-attach-download"
                                                          onClick={() =>
                                                            downloadImage(
                                                              image?.pic_url ||
                                                                '/assets/img/chat-img-01.jpg'
                                                            )
                                                          }
                                                        >
                                                          <i className="fas fa-download" />
                                                        </a>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                              <span className="drop-item message-more-option">
                                                <a
                                                  href="#"
                                                  className="more-vertical-bar"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i className="feather-more-vertical" />
                                                </a>
                                                <small className="dropdown-menu">
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <i className="feather-copy me-2" />
                                                    Copy
                                                  </a>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <i className="feather-trash-2 me-2" />
                                                    Delete
                                                  </a>
                                                </small>
                                              </span>
                                              <ul className="chat-msg-info name-date">
                                                <li>Hendrita</li>
                                                <li>
                                                  <span className="chat-time">
                                                    09:30 am
                                                    <i className="fa-solid fa-check-double un-read" />
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </li>
                                      ) : (
                                        <li className="media sent d-flex">
                                          <div className="avatar flex-shrink-0">
                                            <img
                                              src={
                                                item?.from?.profile_pic
                                                  ? item?.from?.profile_pic
                                                  : `/assets/img/avatar-profile-photo.png`
                                              }
                                              alt="User Imag"
                                              className="avatar-img rounded-circle"
                                            />
                                          </div>
                                          <div className="media-body flex-grow-1">
                                            <div className="msg-box">
                                              <div>
                                                <p>{data?.content}</p>
                                              </div>
                                              <span className="drop-item message-more-option">
                                                <a
                                                  href="#"
                                                  className="more-vertical-bar"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i className="feather-more-vertical" />
                                                </a>
                                                <small className="dropdown-menu">
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <i className="feather-copy me-2" />
                                                    Copy
                                                  </a>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <i className="feather-trash-2 me-2" />
                                                    Delete
                                                  </a>
                                                </small>
                                              </span>
                                              <ul
                                                className="chat-msg-info name-date"
                                                style={{
                                                  minWidth: "160px",
                                                }}
                                              >
                                                <li>
                                                  <span className="chat-time">
                                                    {data?.time}
                                                    <i className="fa-solid fa-check" />
                                                  </span>
                                                </li>
                                                <li>{"Service Advicer"}</li>
                                              </ul>
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                    </>
                                  )}
                                </>
                              ))}
                            </Fragment>
                          ))}
                        </ul>
                      </div>

                      <div className="card-footer">
                        {roomImageLoader ? (
                          <InfinitySpin width="150" color="#ED1D24" />
                        ) : (
                          <div className="input-group">
                            <div className="btn-file btn">
                              <i className="feather-plus" />
                              <input
                                type="file"
                                multiple
                                disabled={roomImageLoader}
                                onChange={handleUploadImage}
                              />
                            </div>
                            <input
                              className="form-control type_msg mh-auto empty_check"
                              placeholder="Write your message..."
                              value={typedMessage || ''}
                              autocomplete="off"
                              onChange={handleChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleSubmit(e);
                                }
                              }}
                            />
                            <div className="send-action">
                              <a href="#">
                                <i className="fa-solid fa-face-smile" />
                              </a>
                              <a href="#">
                                <i className="feather-mic" />
                              </a>
                              <button
                                className="btn btn-primary btn_send"
                                onClick={handleSubmit}
                              >
                                <i
                                  className="fa fa-paper-plane"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>

                {/* /Chat Content */}
              </div>
              {/* /Chat Content */}
            </div>
          </div>
        </div>
      </div>

      <div>
        {/* Cursor */}
        <div className="mouse-cursor cursor-outer" />
        <div className="mouse-cursor cursor-inner" />
        {/* /Cursor */}
      </div>
    </>
  );
};

export default CustomerServiceChat;
