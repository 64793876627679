import { createAsyncThunk } from '@reduxjs/toolkit'
import serviceService from './service'
import { toast } from 'react-toastify'

export const getServices = createAsyncThunk(
  'service/GetServices',
  async (payload, thunkAPI) => {
    try {
      const delivered = await serviceService.getServices(payload)
      console.log('Services delivered', delivered)
      return delivered
    } catch (e) {
      toast(e.message)
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const filterServices = createAsyncThunk(
  'service/FilterServices',
  async (payload, thunkAPI) => {
    try {
      const delivered = await serviceService.filterServices(payload)
      return delivered
    } catch (e) {
      toast(e.message)
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const vinDecoder = createAsyncThunk(
  "service/vinDecoder",
  async (payload, thunkAPI) => {
    try {
      const delivered = await serviceService.vinDecoder(payload);
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const GetAllCategory = createAsyncThunk(
  'service/get_all_category',
  async (payload, thunkAPI) => {
    try {
      let response = await serviceService.getAllCategory()

      return response
    } catch (error) {
      toast(error.message)
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getUserBookings = createAsyncThunk(
  'service/getUserBookings',
  async (paginate, thunkAPI) => {
    try {
      const delivered = await serviceService.getUserBookings(paginate)
      console.log(delivered, 'user bookings delivered')
      return delivered
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getSingleService = createAsyncThunk(
  'services/getSingleService',
  async (id, thunkAPI) => {
    try {
      const delivered = await serviceService.getSingleService(id)
      console.log(delivered, 'get single request')
      return delivered
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const UploadImage = createAsyncThunk(
  'services/service_image',
  async (requestData, thunkAPI) => {
    try {
      const delivered = await serviceService.uploadImage(requestData)

      return delivered
    } catch (e) {
      toast(e.message)
      console.log('Error', e)
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const bookService = createAsyncThunk(
  'services/bookService',
  async (requestData, thunkAPI) => {
    try {
      //   const { id, data } = requestData;
      //   console.log(data, "request data from edit");
      const delivered = await serviceService.bookService(requestData)
      console.log(delivered, 'delivered request')
      return delivered
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
