import { toast } from "react-toastify";
import APIs from "../../Api";
// import APIs from "../Api";

const Login = async (credentials) => {
  try {
    const response = await APIs.post(`/login`, credentials);

    if (response?.data?.message?.toLowerCase() === "success") {
      toast("Login Successfully");

      APIs.defaults.headers[
        "Authorization"
      ] = `Bearer ${response?.data?.token}`;

      toast.success("Login successful!");

      toast("Login Successfully");

      APIs.defaults.headers[
        "Authorization"
      ] = `Bearer ${response?.data?.token}`;

      localStorage.setItem("airdrops_token", response?.data?.token);

      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const UserMakePayment = async (payload) => {
  try {
    const response = await APIs.post("/pay ", payload);

    if (response?.status === 200) {
      toast("Payment Successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const UserRequestRefund = async (payload) => {
  try {
    const response = await APIs.post("/create_customer_refund", payload);

    if (response?.status === 200) {
      toast("Payment Successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const VendorRequestPayout = async (payload) => {
  try {
    const response = await APIs.post("/create_vendor_request_payout", payload);

    if (response?.status === 200) {
      toast("Payment Successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleUser = async (payload) => {
  try {
    const response = await APIs.get(`/get_user`);

    console.log(response, "get user details hot hot");
    if (response?.data?.message === "success") {
      toast("successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const GetUserRefundRequests = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_status_customer_refund_from_users/${payload.status}/${payload.page}/${payload.limit}`
    );

    console.log(response, "get user refund");
    if (response?.data?.message === "success") {
      toast("successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const GetVendorPayoutRequests = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_status_vendor_request_payout_from_vendor/${payload.status}/${payload.page}/${payload.limit}`
    );

    console.log(response, "get vendor request payload");
    if (response?.data?.message === "success") {
      toast("successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const PaymentServices = {
  Login,
  getSingleUser,
  UserMakePayment,
  UserRequestRefund,
  VendorRequestPayout,
  GetVendorPayoutRequests,
  GetUserRefundRequests,
};

export default PaymentServices;
