import { createSlice } from '@reduxjs/toolkit';
import {
  CreateCategory,
  DeleteCategory,
  EditCategoryDetail,
  GetAllCategory,
  GetSingleCategoryDetails,
  UploadCategoryIcon,
  UploadCategoryImage,
} from './category_services_syn';

export const categorySlice = createSlice({
  name: 'category',
  initialState: {
    category_loader: false,
    allCategoryLoader: true,
    categoryDeleteLoader: false,
    categoryEditLoader: false,
    catEditLoader: false,
    message: '',
    completeCategory: [],
    categoryDetails: {},
    categoryImageDetails: {},
    categoryIconDetails: {},
  },
  reducers: {
    clearState: (state) => {
      state.category_loader = false;
      state.message = '';
      state.completeCategory = [];
      state.allCategoryLoader = true;
      state.categoryDeleteLoader = false;
      state.categoryEditLoader = false;
      state.catEditLoader = false;
      state.categoryDetails = {};
      state.categoryImageDetails = {};
      state.categoryIconDetails = {};

      return state;
    },

    updateCategoryDetails: (state, action) => {
      state.categoryDetails = action.payload;
    },

    updateCompleteCategory: (state, action) => {
      state.completeCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CreateCategory.pending, (state) => {
        state.category_loader = true;
      })
      .addCase(CreateCategory.fulfilled, (state, { payload }) => {
        let addCategory = [...payload.data, ...state.completeCategory];

        state.completeCategory = addCategory;

        state.category_loader = false;
        state.message = payload.message;
        state.categoryImageDetails = {};

        const addButton = document.querySelector('[data-bs-dismiss="modal"]');

        if (addButton) {
          addButton.click();
        }
      })
      .addCase(CreateCategory.rejected, (state, { payload }) => {
        state.category_loader = false;
        state.message = payload.message;
      })
      .addCase(UploadCategoryImage.pending, (state) => {
        state.categoryEditLoader = true;
      })
      .addCase(UploadCategoryImage.fulfilled, (state, { payload }) => {
        state.categoryEditLoader = false;
        state.profileMessage = payload.message;
        state.categoryImageDetails = payload.data;
      })
      .addCase(UploadCategoryImage.rejected, (state, { payload }) => {
        state.categoryEditLoader = false;
        state.profileMessage = payload?.message;
      })
      .addCase(UploadCategoryIcon.pending, (state) => {
        state.categoryEditLoader = true;
      })
      .addCase(UploadCategoryIcon.fulfilled, (state, { payload }) => {
        state.categoryEditLoader = false;
        state.profileMessage = payload.message;
        state.categoryIconDetails = payload.data;
      })
      .addCase(UploadCategoryIcon.rejected, (state, { payload }) => {
        state.categoryEditLoader = false;
        state.profileMessage = payload?.message;
      })
      .addCase(GetAllCategory.pending, (state) => {
        state.category_loader = true;
      })
      .addCase(GetAllCategory.fulfilled, (state, { payload }) => {
        state.category_loader = false;
        state.message = payload.message;
        state.completeCategory = payload.data;
      })
      .addCase(GetAllCategory.rejected, (state, { payload }) => {
        state.category_loader = false;
        state.message = payload.message;
      })
      .addCase(DeleteCategory.pending, (state) => {
        state.categoryDeleteLoader = true;
      })
      .addCase(DeleteCategory.fulfilled, (state, { payload }) => {
        const filterData = state.completeCategory?.filter(
          (item) => item._id !== payload?.data?._id
        );

        state.completeCategory = filterData;

        state.categoryDeleteLoader = false;
        state.message = payload.message;

        const addButton = document.querySelector('[data-bs-dismiss="modal"]');

        if (addButton) {
          addButton.click();
        }
      })
      .addCase(DeleteCategory.rejected, (state, { payload }) => {
        state.categoryDeleteLoader = false;
        state.message = payload.message;
      })
      .addCase(GetSingleCategoryDetails.pending, (state) => {
        state.category_loader = true;
      })
      .addCase(GetSingleCategoryDetails.fulfilled, (state, { payload }) => {
        state.category_loader = false;
        state.message = payload.message;
        state.categoryDetails = payload.data;
      })
      .addCase(GetSingleCategoryDetails.rejected, (state, { payload }) => {
        state.category_loader = false;
        state.message = payload.message;
      })
      .addCase(EditCategoryDetail.pending, (state) => {
        state.catEditLoader = true;
      })
      .addCase(EditCategoryDetail.fulfilled, (state, { payload }) => {
        const findIndex = state.completeCategory?.findIndex(
          (item) => item?._id === payload?.data?._id
        );

        state.completeCategory?.splice(findIndex, 1, payload?.data);

        state.completeCategory = state.completeCategory;

        state.catEditLoader = false;
        state.message = payload.message;

        state.categoryImageDetails = {};

        const addButton = document.querySelector('[data-bs-dismiss="modal"]');

        if (addButton) {
          addButton.click();
        }
      })
      .addCase(EditCategoryDetail.rejected, (state, { payload }) => {
        state.catEditLoader = false;
        state.message = payload.message;
      });
  },
});

export const { updateCategoryDetails, updateCompleteCategory } =
  categorySlice.actions;
export const categroySelector = (state) => state.category;

export default categorySlice.reducer;
