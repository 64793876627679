import { createSlice } from "@reduxjs/toolkit";
import {
  bookService,
  getServices,
  getSingleService,
  getUserBookings,
  UploadImage,
  GetAllCategory,
  filterServices,
  vinDecoder,
} from "./servicesSync";

// import serviceService from "./service";
// import serviceservice from "./request-serivce";

export const serviceSlice = createSlice({
  name: "service",
  initialState: {
    // get services
    serviceLimit: 10,
    serviceSkip: 0,
    services: [],
    loadingServices: false,
    errorGettingServices: false,
    getServicesSuccess: false,
    servicesMessage: "",

    //image upload service
    uploadingImage: false,
    uploadingImageSuccess: false,
    uploadingImageFailure: false,
    uploadingImageMessage: "",
    uploadedImage: {},

    // get categories
    completeCategory: [],
    category_loader: false,
    message: "",

    // vin decoder
    gettingVin: false,
    getVinSuccess: false,
    getVinError: false,
    vinData: {},
    vinMessage: "",

    singleService: {},
    userBookings: [],
    isGettingBookings: false,
    isGettingBookingsSuccess: false,
    isGettingBookingsFailure: false,
    getUserBookingErrorMessage: "",
    isFetching: false,
    isPending: false,
    isSuccess: false,
    isError: false,
    isUpdateError: false,
    isUpdateSuccess: false,
    isBooking: false,
    isBookingComplete: false,
    isBookingFailed: false,
    booking: "",
    bookingErrorMessage: "",
    errorMessage: "",
    updateErrorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
    updateServicesSkip: (state, { payload }) => {
      state.authSkip = payload;

      return { ...state };
    },

    updateAllServiceLimit: (state, { payload }) => {
      state.authLimit = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state) => {
        state.isFetching = true;
        console.log("got here and fecthing");
      })
      .addCase(getServices.fulfilled, (state, { payload }) => {
        console.log("got here");
        state.isFetching = false;
        state.isSuccess = true;
        state.services = payload?.data;
        console.log(state.services, "state.services");
      })
      .addCase(getServices.rejected, (state) => {
        console.log("getServices");
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(filterServices.pending, (state) => {
        state.isFetching = true;
        console.log("got here and fecthing");
      })
      .addCase(filterServices.fulfilled, (state, { payload }) => {
        console.log("got here");
        state.isFetching = false;
        state.isSuccess = true;
        state.services = payload?.data;
        console.log(state.services, "state.services");
      })
      .addCase(filterServices.rejected, (state) => {
        console.log("getServices");
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(getSingleService.pending, (state) => {
        state.isFetching = true;
        console.log("got here and fecthing");
      })
      .addCase(getSingleService.fulfilled, (state, { payload }) => {
        console.log("got here");
        state.isFetching = false;
        state.isSuccess = true;
        state.singleService = payload?.data;
        console.log(state.singleService, "services");
      })
      .addCase(getSingleService.rejected, (state) => {
        console.log("getServices");
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(bookService.pending, (state, action) => {
        state.isBooking = true;
        console.log("pending");
      })
      .addCase(bookService.fulfilled, (state, action) => {
        state.isBookingComplete = true;
        state.booking = action.payload;
        state.isBooking = false;
        console.log(state.booking, "booking");
      })
      .addCase(bookService.rejected, (state, action) => {
        state.isBooking = false;
        state.isBookingFailed = true;
        state.bookingErrorMessage = action.payload;
        console.log("booking failed", state.bookingErrorMessage);
      })
      .addCase(getUserBookings.pending, (state, action) => {
        state.isGettingBookings = true;
        console.log("pending");
      })
      .addCase(getUserBookings.fulfilled, (state, action) => {
        state.isGettingBookingsSuccess = true;
        state.userBookings = action.payload;
        state.isGettingBookings = false;
        console.log(state.userBookings, "user booking");
      })
      .addCase(getUserBookings.rejected, (state, action) => {
        state.isGettingBookings = false;
        state.isGettingBookingsFailure = true;
        state.getUserBookingErrorMessage = action.payload;
        console.log("booking failed", state.getUserBookingErrorMessage);
      })
      .addCase(UploadImage.pending, (state) => {
        state.uploadingImage = true;
      })
      .addCase(UploadImage.fulfilled, (state, { payload }) => {
        state.uploadingImage = false;
        state.uploadingImageMessage = payload.message;
        state.uploadingImageSuccess = true;
        state.uploadedImage = payload.data;
      })
      .addCase(UploadImage.rejected, (state, { payload }) => {
        state.uploadingImage = false;
        state.uploadingImageMessage = payload?.message;
        state.uploadingImageFailure = true;
      })
      .addCase(GetAllCategory.pending, (state) => {
        state.category_loader = true;
      })
      .addCase(GetAllCategory.fulfilled, (state, { payload }) => {
        state.category_loader = false;
        state.message = payload.message;
        state.completeCategory = payload.data;
      })
      .addCase(GetAllCategory.rejected, (state, { payload }) => {
        state.category_loader = false;
        state.message = payload.message;
      })
      .addCase(vinDecoder.pending, (state) => {
        state.gettingVin = true;
      })
      .addCase(vinDecoder.fulfilled, (state, { payload }) => {
        state.gettingVin = false;
        // state.message = payload.message;
        state.getVinSuccess = true;
        state.vinMessage = payload?.message;
        state.vinData = payload?.data;
      })
      .addCase(vinDecoder.rejected, (state, { payload }) => {
        state.gettingVin = false;
        state.getVinError = true;
        state.vinMessage = payload?.message;
        console.log("vinDecoder rejected", payload);
      });
  },
});

export const { clearState, updateAllServiceLimit, updateServicesSkip } =
  serviceSlice.actions;
export const serviceSelector = (state) => state.services;
